import envConfig from "../../enviroment/enviroment";


const APP_VERSION = envConfig.getEnvKey('APP_VERSION');

type props = {
    theme: 'dark' | 'light'
}
export const AppVersion = ({ theme }: props) => {
    return (
        <div className="text-[0.7rem]" style={{ color: theme === 'dark' ? '#011529' : '#afb6bb' }}>
            {APP_VERSION && <span className='italic'>Version: {APP_VERSION}</span>}
        </div>
    );
};
