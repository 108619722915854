import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { LeaveData, LeaveRequest } from '../mock/Leave';
import { LeaveOptions } from '../@types/FetchOptions';
import { LeaveStatus } from '../@types/Leave';
import { LeaveRequestPost } from '../@types/Request';
import { CommonListResponse } from '../@types/Response';

export const LeaveTypedStatus = {
  0: 'Submitted',
  1: 'Approved',
  2: 'Rejected',
  3: 'Withdraw',
  4: 'Gathering Approvals',
} as const;

export const LeaveTypeColor = {
  0: '#fcc071',
  1: '#d6d5ff',
  2: '#FF6347',
  3: '#4169E1',
  4: '#4169E1',
} as const;

export function isLeaveStatus(status: unknown): status is LeaveStatus {
  return (status as LeaveStatus) in LeaveTypedStatus;
}

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
const GET_WORKABLE_DAYS = `${API_URL}/leaves/number-of-working-days`;
export const CREATE_LEAVE = `${API_URL}/leaves/submit`;
export const GET_LEAVES = `${API_URL}/leaves/list`;
export const GET_APPROVAL_LEAVES = `${API_URL}/leaves/my-approvals`;
export const GET_MY_LEAVES = `${API_URL}/leaves/my-requests`;
export const GET_LEAVE_BY_ID = `${API_URL}/leaves/get-request`;
export const WITHDRAW_LEAVES = `${API_URL}/leaves/withdraw`;
export const APPROVE_LEAVE = `${API_URL}/leaves/approve`;
export const REJECT_LEAVE = `${API_URL}/leaves/reject`;
export const GET_SINGLE_APPROVAL = `${API_URL}/leaves/get-approval`;

export const getWorkableDays = async (
  start: string,
  end: string,
  country_code: string,
): Promise<number> => {
  try {
    const response = await axios.get<number>(
      `${GET_WORKABLE_DAYS}?start=${start}&end=${end}&country_code=${country_code}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching workable days:', error);
    throw error;
  }
};

export const createLeave = async (data: LeaveRequestPost) => {
  try {
    const response = await axios.post<''>(CREATE_LEAVE, data);
    return response.data;
  } catch (error) {
    console.error('Error creating leave:', error);
    throw error;
  }
};

export const getApprovalLeaveSingle = async (id: number) => {
  try {
    const response = await axios.get<CommonListResponse<LeaveRequest>>(
      GET_SINGLE_APPROVAL + `?id=${id}`,
    );
    // await SingleApprovalSchema.validate(response.data);
    return response.data;
  } catch (error) {
    console.error('Error geeting approval leave single', error);
    throw error;
  }
};
export const approveSingleLeave = async (data: {
  id: number;
  comments: string;
}) => {
  try {
    const response = await axios.post(`${APPROVE_LEAVE}`, {
      id: data.id,
      comments: data.comments,
    });
    return response.data;
  } catch (error) {
    console.error('Error aproving leave:', error);
    throw error;
  }
};
export const rejectSignleLeave = async (data: {
  id: number;
  comments: string;
}) => {
  try {
    const response = await axios.post(`${REJECT_LEAVE}`, {
      id: data.id,
      comments: data.comments,
    });
    return response.data;
  } catch (error) {
    console.error('Error rejecting leave:', error);
    throw error;
  }
};
export const fetchMyLeaves = async ({
  pageIndex = 0,
  pageSize = 10,
  sorting = 'EndDate:desc',
  searchTerm = '',
  status = '0,1,2,3',
  from = '',
  to = '',
}: LeaveOptions) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    searchTerm,
    sorting,
    status,
    from,
    to,
  });

  const response = await axios.get<CommonListResponse<LeaveData>>(
    `${GET_MY_LEAVES}?${params.toString()}`,
  );
  // await leaveRequestSchema.validate(response.data)
  return response.data;
};
export const fetchApprovalList = async ({
  pageIndex = 0,
  pageSize = 10,
  sorting = 'Request.StartDate:asc',
  searchTerm = '',
  status = '0,1,2,3',
  from = '',
  to = '',
  personIds = '',
  advancedFilter = '',
}: LeaveOptions) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    searchTerm,
    sorting,
    status,
    from,
    to,
    personIds,
    advancedFilter,
  });
  try {
    const response = await axios.get<CommonListResponse<LeaveRequest>>(
      `${GET_APPROVAL_LEAVES}?${params.toString()}`,
    );
    // await ApprovalSchema.validate(response.data.Results);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const fetchLeaves = async ({
  pageIndex = 0,
  pageSize = 10,
  sorting = 'StartDate:asc',
  searchTerm = '',
  status = '',
  from = '',
  to = '',
  personIds = '',
}: LeaveOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    searchTerm,
    sorting,
    status,
    from,
    to,
    personIds,
  });
  try {
    const response = await axios.get<CommonListResponse<LeaveData>>(
      `${GET_LEAVES}?${params.toString()}`,
    );
    // await ListLeaveSchema.validate(response.data.Results);
    return response.data;
  } catch (error) {
    console.error('Error fetching leaves:', error);
    throw error;
  }
};
export const fetchLeaveById = async (id: number) => {
  try {
    const response = await axios.get<LeaveData>(`${GET_LEAVE_BY_ID}?id=${id}`);
    // leaveRequestSchema.validate(response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching timesheet by id:', error);
    throw error;
  }
};
export const withdrawLeave = async (id: number) => {
  try {
    const response = await axios.post(WITHDRAW_LEAVES, JSON.stringify(id));
    return response.data;
  } catch (error) {
    console.error('Error fetching timesheet by id:', error);
    throw error;
  }
};
