import { Navigate, Route, Routes } from 'react-router-dom';
import { Error500 } from '../features/errors/components/Error500';
import { Error404 } from '../features/errors/components/Error404';
import { ErrorsLayout } from '../features/errors/ErrorsLayout';

const ErrorsRoutes = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path="404" element={<Error404 />} />
      <Route path="500" element={<Error500 />} />
      {/* The following route will match any other path under "/errors/" */}
      <Route path="/*" element={<Navigate to="/404" />} />
    </Route>
  </Routes>
);

export default ErrorsRoutes;
