import {
  FC,
  ReactNode,
  ReactPortal,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ProjectContext from './ProjectContext';
import {
  ParticipantReports,
  ProjectExtended,
  ProjectReporting,
  Report,
} from '../../../../@types/Project';
import {
  fetchParticipantList,
  fetchPeriodicReports,
  fetchProjects,
  FetchProjectsOptions,
  fetchProjectsParticipation,
  fetchReportingPeriod,
} from '../../../../api-requests/Projects';

type TUserProviderProps = {
  children: ReactNode | ReactPortal;
};

export const ProjectProvider: FC<TUserProviderProps> = ({ children }) => {
  const [projects, setProjects] = useState<ProjectExtended[]>([]);
  const [projectReportings, setProjectReportings] = useState<
    ProjectReporting[]
  >([]);
  const [participantReports, setParticipantsReports] = useState<
    ParticipantReports[]
  >([]);
  const [participantReportsTotal, setParticipantsReportsTotal] =
    useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalReportings, setTotalReportings] = useState(0);
  const [periodicReports, setPeriodicReports] = useState<ProjectReporting[]>(
    [],
  );
  const [reportingPeriods, setReportingPeriods] = useState<Report[]>([]);
  const [reportingPeriodsTotal, setReportingPeriodsTotal] = useState(0);
  const [periodicReportsTotal, setPeriodicReportsTotal] = useState(0);
  const [activeProject, setActiveProject] = useState(0);
  const [activeRP, setActiveRP] = useState(0);
  const getProjects = async (params: FetchProjectsOptions) => {
    setLoading(true);
    console.log('params', params)
    try {
      const projectResponse = await fetchProjects({ ...params });
      setProjects(projectResponse.Results);
      setTotalProjects(projectResponse.TotalResults);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };
  const getParticipantReports = async (params: FetchProjectsOptions) => {
    setLoading(true);
    try {
      if (Number(params.projectId) !== 0 && params.projectId !== 'null') {
        const participantResponse = await fetchParticipantList({ ...params });
        setParticipantsReports(participantResponse.Results);
        setParticipantsReportsTotal(participantResponse.TotalResults);
      } else {
        setParticipantsReports([]);
        setParticipantsReportsTotal(0);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setParticipantsReports([]);
      setParticipantsReportsTotal(0);
      return error;
    } finally {
      setLoading(false);
    }
  };
  const getProjectReportings = async (params: FetchProjectsOptions) => {
    setLoading(true);
    try {
      const projectReportingsResponse = await fetchProjectsParticipation({
        ...params,
      });
      setProjectReportings(projectReportingsResponse.Results);
      setTotalReportings(projectReportingsResponse.TotalResults);
    } catch (error) {
      console.error('Error fetching reportings', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const getPeriodicReports = async (params: FetchProjectsOptions) => {
    setLoading(true);
    try {
      const projectResponse = await fetchPeriodicReports({ ...params });
      setPeriodicReports(projectResponse.Results);
      setPeriodicReportsTotal(projectResponse.TotalResults);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  const getReportingPeriods = async (id: number) => {
    setLoading(true);
    try {
      const projectResponse = await fetchReportingPeriod({
        value: id,
        formal: false,
      });
      setReportingPeriods(projectResponse);
      setReportingPeriodsTotal(projectResponse.length);
    } catch (error) {
      console.error('Error fetching reporting period:', error);
      setReportingPeriods([]);
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      getProjects,
      getReportingPeriods,
      reportingPeriods,
      reportingPeriodsTotal,
      getPeriodicReports,
      periodicReports,
      periodicReportsTotal,
      projects,
      totalProjects,
      loading,
      projectReportings,
      getProjectReportings,
      totalReportings,
      getParticipantReports,
      participantReports,
      participantReportsTotal,
      activeProject,
      setActiveProject,
      activeRP,
      setActiveRP,
      setProjects,
      setParticipantsReports,
      setPeriodicReports,
      setProjectReportings,
    }),
    [
      projects,
      loading,
      projectReportings,
      participantReports,
      reportingPeriods,
      activeProject,
      activeRP,
      setActiveProject,
      setActiveRP,
    ],
  );
  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
};
