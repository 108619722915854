import React from "react";
import { useFeature } from "../../app/features/feature/FeatureProvider";
import { IFeatures } from "../../enviroment/env.type";


type Props = {
    children: React.ReactNode;
    keyVal: keyof IFeatures
}


const HideComponent = ({ children, keyVal }: Props) => {
    const { showFeature } = useFeature();
    if (showFeature(keyVal)) {
        return children;
    }
    return null;
};

export default HideComponent;