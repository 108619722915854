import { Button, Table, TableColumnType, } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import useTableContext from '../../../table/provider/TableContext';
import ActionCell from '../../../../components/cells/ActionCell';
import { useAuth } from '../../../auth';
import WarningModal from '../../../../components/modal/WarningModal';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import { LeaveColors } from '../../../../../shared/colors';
import TableFilters from '../../../../utils/TableFilters/TableFilters';
import { useTeam } from '../provider/useTeam';
import TeamCreateModal from './teamCreateModal';
import AvatarGroupCell from '../../../../components/cells/AvatarGroupCell';
import { ExtendedTableColumnType } from '../../../timesheet/components/TimesheetTable';
import { ErrorResponse } from '../../../../@types/Errors';
import { Team } from '../../../../@types/Team';
import { initTeam } from '../../../../utils/FormValidation/TeamFormValidation';
import { getInitialColumns, TeamActionPerRow } from './Columns';
import LoomTable from '../../../table/components/LoomTable';


export interface CustomColumnType<T> extends TableColumnType<T> {
    default?: boolean;
}
const TeamTable = () => {
    const { teams, teamsTotal, loading, deleteTeam } = useTeam();
    const { id, allowIf } = useAuth();
    const { openNotification } = useNotifications();
    const [createModal, setCreateModal] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const [teamSelected, setTeamSelected] = useState<Team>(initTeam)
    const [selectedId, setSelectedId] = useState(0);
    const [columns, setColumns] = useState<ExtendedTableColumnType<Team>[]>(
        () =>
            getInitialColumns(
                id,
                setTeamSelected,
                setCreateModal,
                setWarningOpen,
                setSelectedId,
                allowIf
            ),
    );

    const handleDeleteTeam = () => {
        try {
            deleteTeam(selectedId);
            openNotification('topRight', {
                title: `Team`,
                description: 'Team deleted successfully.',
                type: 'Success',
            });
        } catch (err) {
            const error = err as ErrorResponse;
            const txtErr = error.response?.data?.error || 'An error occurred';
            openNotification('topRight', {
                title: `Team`,
                description: txtErr,
                type: 'Danger',
            });
        } finally {
            setWarningOpen(false);
        }
    }
    return (
        <>
            <WarningModal
                title="Delete Team"
                isOpen={warningOpen}
                setIsOpen={setWarningOpen}
                footerButtons={[
                    <Button onClick={() => setWarningOpen(false)}>Close</Button>,
                    <Button
                        onClick={() => {
                            handleDeleteTeam()
                        }}
                        style={{ background: LeaveColors[2].value, color: 'white' }}
                    >
                        Delete
                    </Button>,
                ]}
                mainText="Are you sure you want to delete this team? Your action will remove it permanently."
            />
            <TeamCreateModal isOpen={createModal} data={teamSelected} setData={setTeamSelected} onClose={() => { setCreateModal(false); setTeamSelected(initTeam) }} setIsOpen={setCreateModal} fetchData={() => { }} />
            <TableFilters<Team>
                hasFilters={false}
                localStorageKey='TeamTableColumns'
                addNavigate={() => setCreateModal(true)}
                setIsCreateModal={setCreateModal}
                columns={columns}
                newColumns={columns}
                setNewColumns={setColumns}
                assigneesFilter={true}
                hasStatus={false}
            />
            <LoomTable<Team> data={teams} columns={columns} totalData={teamsTotal} loadingDataFetch={loading} actions={(record) => TeamActionPerRow(record, id, setTeamSelected, setCreateModal, setWarningOpen, setSelectedId, allowIf)} />

        </>
    );
};

export default TeamTable;
