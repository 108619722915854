import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  UnorderedListOutlined,
  PrinterOutlined,
  CalendarOutlined,
  PlusCircleOutlined,
  ClockCircleOutlined,
  CarOutlined,
  BugFilled,
  MenuOutlined,
  ToTopOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Layout, Menu, Typography, Grid } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SidebarLogo } from './SidebarLogo';
import { useAuth } from '../../features/auth';
import SimpleBar from 'simplebar-react';
import CalendarModalForm from '../../features/leave/components/Form/CalendarModalForm';
import { useFeature } from '../../features/feature/FeatureProvider';
import ReportModal from '../../features/report/ReportModal';
import { initLeaveRequest } from '../../utils/FormValidation/LeaveFormValidation';
import {
  MyLeaveContext,
  useLeaveContext,
} from '../../features/leave/provider/GenericLeaveProvider';
import useRemainingHeight from '../../hooks/useRemainingHeight.hook';
import { BalanceProvider } from '../../features/leave/provider/BalanceProvider';
import useRouting from '../../hooks/routing.hook';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

type Props = {
  collapsed: boolean;
  setCollapsed: (d: boolean) => void;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Sidebar = ({ collapsed, setCollapsed }: Props) => {

  const { showFeature } = useFeature();
  const { xs } = useBreakpoint();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const { filteredMenuStructure, getIndexRouteKey, getParentKey } =
    useRouting();

  const { allowIf } = useAuth();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [data, setData] = useState(initLeaveRequest);
  const [openReport, setOpenReport] = useState(false);
  const { fetchLeavesFunc } = useLeaveContext(MyLeaveContext);
  const location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === '/' || location.pathname === ''
      ? '/dashboard'
      : location.pathname,
  );

  const topLogoRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const contentHeight = useRemainingHeight([topLogoRef, footerRef]);
  const [menuOpenedKeys, setMenuOpenedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  useEffect(() => {
    // Function to update dimensions
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    //open only the first level of menu if its ony one and has children
    setMenuOpenedKeys([getParentKey(location.pathname as string) as string]);


    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuItems = useMemo(() => {
    const mapMenuStructure = (menu: any) =>
      menu
        .filter((item: any) => item.show) // Filter items where `show` is false
        .map((item: any) => ({
          key: item.key,
          icon: React.createElement(item.icon),
          label: item.children ? (
            item.label // Use plain text for parent items
          ) : (
            <Link to={item.key}>{item.label}</Link> // Wrap child labels in Link
          ),
          children: item.children ? mapMenuStructure(item.children) : undefined, // Recursively map children
        }));

    return mapMenuStructure(filteredMenuStructure);
  }, [filteredMenuStructure]);
  const items: MenuProps['items'] = [
    showFeature('TIMESHEET') && allowIf(['Timesheets_ReadWrite'])
      ? {
        key: 'add_timesheet',
        label: <Link to="/dashboard/timesheet/create">Timesheet</Link>,
        icon: <UnorderedListOutlined />,
      }
      : null,

    showFeature('LEAVES') &&
      (allowIf(['LeaveHistory_View']) || allowIf(['Meetings_Read']))
      ? {
        key: 'add_leave',
        icon: <CalendarOutlined />,
        label: (
          <Typography onClick={() => setCreateModalOpen(true)}>
            Leave
          </Typography>
        ),
      }
      : null,

    showFeature('MEETING') &&
      (allowIf(['Meetings_Read']) || allowIf(['Trips_EditAll']))
      ? {
        key: 'add_projectMeeting',
        icon: <ClockCircleOutlined />,
        label: <Link to="/dashboard/projects/meetings/create">Meeting</Link>,
      }
      : null,
    showFeature("MEETING") && (allowIf(["Meetings_Read"]) || allowIf(["Trips_EditAll"]))
      ? {
        key: 'add_trip',
        icon: <CarOutlined />,
        label: <Link to="/dashboard/projects/meetings/trip/create">Trip</Link>,
      }
      : null,
  ].filter(Boolean);

  return (
    <Sider
      collapsedWidth={xs ? 0 : 120}
      width={xs ? 250 : 250}
      collapsible
      collapsed={collapsed}
      trigger={!xs && null}
      onCollapse={(value) => {
        xs ? setCollapsed(!value) : setCollapsed(value);
      }}
      className={`flex flex-col justify-between sticky top-0 h-screen z-50`}  >
      {isCreateModalOpen && (
        <BalanceProvider>
          <CalendarModalForm
            fetchData={fetchLeavesFunc}
            isOpen={isCreateModalOpen}
            setIsOpen={setCreateModalOpen}
            data={data}
            setData={setData}
          />
        </BalanceProvider>
      )}
      {openReport && (
        <ReportModal isOpen={openReport} onClose={() => setOpenReport(false)} />
      )}
      <div className="flex flex-col justify-start h-full">
        <div
          className={`flex pb-4 flex-row items-center ${collapsed ? 'justify-center mt-2' : 'mt-2'}`}
          ref={topLogoRef}
        >
          <div className="p-2 flex flex-col items-center w-full">
            <Button
              data-testid="reportBtn"
              onClick={() => setCollapsed(!collapsed)}
              className="!w-[50px] bg-[#1677ff] text-white border border-[#3c444b] rounded-[0.45rem]  hover:!bg-[#3887f5] hover:!border-[#3a4046] hover:!text-white"
              icon={
                !collapsed ? (
                  <ToTopOutlined style={{ transform: 'rotate(-90deg)' }} />
                ) : (
                  <MenuOutlined />
                )
              }
            ></Button>
            <div className="mt-6"></div>
            <SidebarLogo setCollapsed={setCollapsed} collapsed={collapsed} />
          </div>
        </div>

        {/* Scrollable content */}
        <SimpleBar
          forceVisible={true}
          autoHide={false}
          style={{ maxHeight: contentHeight }}
          className="px-4"
        >
          <Menu
            inlineIndent={10}
            theme="dark"
            selectedKeys={[current]}
            mode="inline"
            items={menuItems}
            onOpenChange={keys => setMenuOpenedKeys([...keys])}
            openKeys={menuOpenedKeys}
          />
        </SimpleBar>
        {/* <div
          className={`flex-grow overflow-hidden hover:overflow-y-scroll mt-5 [scrollbar-gutter:stable]`}>
          <Menu
            inlineIndent={10}
            theme="dark"
            selectedKeys={[current]}
            mode="inline"
            items={menuItems}
          />
        </div> */}
        {/* Report button at the bottom */}
        <div
          ref={footerRef}
          className="absolute bottom-0 left-0 w-[100%] z-30 h-[100px] flex flex-col justify-end items-end  p-2"
        >
          {items.length > 0 && (
            <div>
              <Dropdown menu={{ items }} className="mb-3">
                <Button
                  data-testid="addEntryBtn"
                  className="!w-[60px] bg-[#1677ff] text-white border border-[#3c444b] rounded-full h-[55px] hover:!bg-[#3887f5] hover:!border-[#3a4046] hover:!text-white"
                  style={{
                    width: '100%', // Adjust width based on collapse state
                    transform: 'translateX(30px)',
                  }}
                  icon={<PlusCircleOutlined size={64} />}
                ></Button>
              </Dropdown>
            </div>
          )}
          <div className="w-full">
            <div className="w-full">
              <Button
                data-testid="reportBtn"
                onClick={() => setOpenReport(true)}
                className="w-full bg-[#1677ff] text-white border border-[#3c444b] rounded-[0.45rem] h-[45px] hover:!bg-[#3887f5] hover:!border-[#3a4046] hover:!text-white flex items-center"
                style={{
                  width: '100%',
                }}
                icon={<BugFilled style={{ fontSize: '16px', verticalAlign: 'middle' }} />} // Adjust font size and vertical alignment
              >
                {collapsed && !xs ? '' : 'Report an issue'}
              </Button>

            </div>

          </div>
        </div>
      </div>
    </Sider >
  );
};

export default Sidebar;
