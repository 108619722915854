import { lazy, FC, Suspense, useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { MasterLayout } from '../pages/layout/MasterLayout';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { WithChildren } from '../@types/Utils';
import { useFeature } from '../features/feature/FeatureProvider';
import HumanResourcesRoutes from './HumanResourcesRoutes';
import { RunTimeError } from '../features/errors/components/RunTimeError';
import useTrackLocation from '../hooks/useTrackLocation';
import useRouting from '../hooks/routing.hook';
import ErrorsRoutes from './ErrorsRoutes';

const TimesheetRoutes = lazy(() => import('./TimesheetRoutes'));
const LeaveRoutes = lazy(() => import('./LeaveRoutes'));
const ProjectRoutes = lazy(() => import('./ProjectRoutes'));
const MeetingRoutes = lazy(() => import('./MeetingRoutes'));
const TripRoutes = lazy(() => import('./TripRoutes'));

const PrivateRoutes = () => {
  const { showFeature } = useFeature();
  const navigate = useNavigate();

  const { getIndexRouteKey, isRouteExist } =
    useRouting();

  useTrackLocation();

  useEffect(() => {
    setTimeout(() => {
      const prevRoute = localStorage.getItem('redirectAfterLogin')
      const indexRoute = getIndexRouteKey as string;
      if (!prevRoute) {
        navigate(indexRoute)
        return
      }
      // if the prev route is not found into the new routes created from permissions
      navigate(isRouteExist(prevRoute) ? prevRoute : indexRoute);
    }, 200);
  }, []);
  return (
    <Routes>
      <Route
        path="dashboard"
        element={<MasterLayout />}
        errorElement={<RunTimeError />}
      >

        {(showFeature('LEAVES') || showFeature('TIMESHEET')) && <Route path="" element={<DashboardWrapper />} />}

        {/* Pages */}
        {/* Lazy Modules */}
        {showFeature('TIMESHEET') && (
          <Route
            path="timesheet/*"
            element={
              <SuspensedView>
                <TimesheetRoutes />
              </SuspensedView>
            }
          />
        )}

        {showFeature('LEAVES') && (
          <Route
            path="leave/*"
            element={
              <SuspensedView>
                <LeaveRoutes />
              </SuspensedView>
            }
          />
        )}
        {showFeature('HUMAN_RESOURCES') && (
          <Route
            path="human/*"
            element={
              <SuspensedView>
                <HumanResourcesRoutes />
              </SuspensedView>
            }
          />
        )}
        (
        <Route
          path="projects/*"
          element={
            <SuspensedView>
              <ProjectRoutes />
            </SuspensedView>
          }
        />
        )
        {showFeature('MEETING') && (
          <Route
            path="projects/meetings/*"
            element={
              <SuspensedView>
                <MeetingRoutes />
              </SuspensedView>
            }
          />
        )}
        {showFeature('MEETING') && (
          <Route
            path="projects/meetings/trip/*"
            element={
              <SuspensedView>
                <TripRoutes />
              </SuspensedView>
            }
          />
        )}
        {/* Page Not Found */}
        {/* <Route path="*" element={<ErrorsRoutes />} /> */}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  return <Suspense>{children}</Suspense>;
};

export default PrivateRoutes;
