// @ts-nocheck
import { TeamType } from '../../../app/api-requests/Employees';
import './index.css';
import Loader from '../../../app/components/Loader';
import CustomAvatar from '../avatar/CustomAvatar';
import useTableContext from '../../../app/features/table/provider/TableContext';
import { Drawer, Typography } from 'antd';
import { DebouncedInput } from '../DebouncedInput/DebouncedInput';
import { useTeam } from '../../features/humanResources/teams/provider/useTeam';
import { EmployeeSimple } from '../../@types/Employee';
import { useEffect } from 'react';

type SidebarProps = {
  collapsed: boolean;
  setCollapsed: (d: boolean) => void;
  searchTerm: string;
  setSearchTerm: (d: string) => void;
  employeeOptions: Array<EmployeeSimple>;
  title?: string;
};
const EmployeeSidebar = ({
  title,
  collapsed,
  setCollapsed,
  searchTerm,
  setSearchTerm,
  employeeOptions,
}: SidebarProps) => {
  const { teams, getTeams } = useTeam();
  const { filterByEmployee, employeeIds, filterTeam } = useTableContext();

  const checkTeamActive = (team: TeamType) => {
    const teamIds = [String(team.Leader?.Id), ...team.Members.map(member => String(member.Id))];
    // Check if every team ID is included in the employeeIds array
    return teamIds.every(id => employeeIds.includes(id));
  }

  function removeAllTeamIds(teamDis: TeamType) {

    const activeTeams = teams
      .filter((team) => team.Id !== teamDis.Id)
      .map((team) => {
        const teamIds = [
          team.Leader ? String(team.Leader.Id) : '',
          ...team.Members.map((member) => String(member.Id)),
        ];

        // Check if all team IDs are included in employeeIds
        const isActive = teamIds.every((id) => employeeIds.includes(id));
        if (isActive) {
          return team;
        }
      })
      .filter((team) => team !== undefined);

    const otherTeamEmployeeIds2 = activeTeams
      .filter((team) => team.Id !== teamDis.Id) // Exclude the team being disabled
      .flatMap((team) => [
        String(team.Leader.Id), // Leader ID as string
        ...team.Members.map((member) => String(member.Id)), // Member IDs as strings
      ]);

    const remainingEmployeeIds = employeeIds.filter((id) =>
      otherTeamEmployeeIds2.includes(id),
    );

    return remainingEmployeeIds;
  }
  useEffect(() => {
    getTeams();
  }, [])
  return (
    <>
      {
        <Drawer
          width={300}
          title="Employee Filter"
          onClose={() => setCollapsed(false)}
          open={collapsed}
        >
          <div
            data-testid="drawerContent"
            id="example-collapse-text"
            style={{
              gap: 4,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 5,
              paddingRight: 5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="flex justify-center items-start gap-4">
              <DebouncedInput
                onChange={(e) => setSearchTerm(String(e))}
                initValue={searchTerm}
                className="form-control form-control-solid w-250px ps-14 mb-2"
                placeholder="Search..."
                type="search"
                pre={true}
              />
              {/* {employeeIds.length > 0 ? <Button className='w-16' onClick={() => { setSearchTerm(''); filterByEmployee(employeeIds.filter((emp) => emp === '')) }}>Clear</Button> : <div></div>} */}
            </div>
            {employeeOptions &&
              employeeOptions.length === 0 &&
              teams.length === 0 ? (
              <Loader />
            ) : (
              <>
                <div className="flex justify-between mt-4">
                  <Typography style={{ color: '#6e6e6e', fontSize: 12 }}>
                    {title ? title : 'Employees'}
                  </Typography>
                  <Typography
                    className="cursor-pointer"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      if (employeeIds.length > 0) filterByEmployee([]);
                      else {
                        filterByEmployee(
                          employeeOptions.map((emp) => String(emp.Id)),
                        );
                      }
                    }}
                  >
                    {employeeIds.length > 0 && employeeIds[0] !== ''
                      ? 'Clear All'
                      : 'Select all'}
                  </Typography>
                </div>
                <div className="max-h-[400px] overflow-y-scroll">
                  {employeeOptions
                    // Sort to move selected employees to the top, maintaining the original order for deselected ones
                    .sort((a, b) => {
                      const isASelected = employeeIds.includes(String(a.Id));
                      const isBSelected = employeeIds.includes(String(b.Id));

                      // If both are selected or both are not selected, maintain the original order
                      if (isASelected === isBSelected) return 0;

                      // If A is selected, it should come first, otherwise B comes first
                      return isASelected ? -1 : 1;
                    })
                    .map((emp, i) => {
                      return (
                        <div
                          key={i}
                          className="buttonEmp"
                          style={{
                            backgroundColor: employeeIds.includes(
                              String(emp.Id),
                            )
                              ? ' rgba(127, 119, 241, 0.1)'
                              : '',
                            color: employeeIds.includes(String(emp.Id))
                              ? '#7f77f1'
                              : '',
                            fontWeight: employeeIds.includes(String(emp.Id))
                              ? 500
                              : 400,
                            height: 30,
                            gap: 12,
                            paddingRight: 10,
                            cursor: 'pointer',
                            marginBottom: '10px',
                            paddingLeft: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            const newEmployeeIds = employeeIds.includes(
                              String(emp.Id),
                            )
                              ? employeeIds.filter(
                                (item) => item !== String(emp.Id),
                              ) // Deselect
                              : [...employeeIds, String(emp.Id)]; // Select
                            filterByEmployee(newEmployeeIds);
                          }}
                        >
                          <div
                            className="col-span-8"
                            style={{
                              position: 'relative',
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              gap: 12,
                            }}
                          >
                            <CustomAvatar
                              avatarProps={{
                                initials: (emp.FirstName && emp.LastName) ? (emp.FirstName[0] + emp.LastName[0]) : '',
                                avatarColour: emp.avatarColour,
                                name: emp.FirstName + ' ' + emp.LastName,
                              }}
                            />
                            <h4 style={{ margin: 0, fontSize: 13, fontWeight: 'normal' }}>
                              {emp.FirstName && emp.FirstName[0]}.{emp.LastName && emp.LastName}
                            </h4>
                          </div>
                          <div
                            className="checkbox-container"
                            style={{
                              borderRadius: '6px',
                              position: 'relative',
                              height: '100%',
                              display: 'flex',
                              cursor: 'pointer',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{
                                position: 'absolute',
                                opacity: 0,
                                cursor: 'pointer',
                              }}
                            />
                            <span
                              className="checkmark"
                              style={{
                                position: 'relative',
                                display: 'inline-block',
                                width: 14,
                                height: 14,
                                background: '#7f77f1',
                                opacity: employeeIds.includes(String(emp.Id))
                                  ? 1
                                  : 0.3,
                                borderRadius: '50%',
                              }}
                            >
                              <i
                                className="fas fa-check"
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%,-50%)',
                                  color: '#fff',
                                  fontSize: 16,
                                  display: 'block',
                                }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="flex justify-between mt-4">
                  <Typography style={{ color: '#6e6e6e', fontSize: 12 }}>
                    Teams
                  </Typography>
                </div>
                {teams.map((team, i: number) => (
                  <div
                    key={i}
                    className="buttonEmp"
                    style={{
                      backgroundColor: checkTeamActive(team)
                        ? ' rgba(127, 119, 241, 0.1)'
                        : '',
                      color: checkTeamActive(team) ? '#7f77f1' : '',
                      fontWeight: checkTeamActive(team) ? 500 : 400,
                      height: 30,
                      gap: 12,
                      paddingRight: 10,
                      cursor: 'pointer',
                      marginBottom: '10px',
                      paddingLeft: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      const tmp = [];
                      tmp.push(String(team.Leader.Id));
                      team.Members.map((member) => {
                        tmp.push(String(member.Id));
                      });
                      if (!checkTeamActive(team)) {
                        team.active = true;
                        filterTeam([...employeeIds, ...tmp]);
                      } else {
                        team.active = false;
                        filterTeam(removeAllTeamIds(team));
                      }
                    }}
                  >
                    <div
                      className="col-span-8"
                      style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        gap: 12,
                      }}
                    >
                      <CustomAvatar
                        avatarProps={{
                          initials: team.Name[0] + team.Name[1],
                          avatarColour: team.Name,
                          name: team.Name,
                        }}
                      />
                      <h4
                        style={{
                          margin: 0,
                          fontSize: 13,
                          fontWeight: 'normal',
                        }}
                      >
                        {team.Name}
                      </h4>
                    </div>

                    <div
                      className="checkbox-container"
                      style={{
                        borderRadius: '6px',
                        position: 'relative',
                        height: '100%',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                      />
                      <span
                        className="checkmark"
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          width: 14,
                          height: 14,
                          background: '#7f77f1',
                          opacity: checkTeamActive(team) ? 1 : 0.3,
                          borderRadius: '50%',
                        }}
                      >
                        {/* Add your icon here */}
                        <i
                          className="fas fa-check"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            color: '#fff',
                            fontSize: 16,
                            display: 'block',
                          }}
                        ></i>
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </Drawer>
      }
    </>
  );
};

export default EmployeeSidebar;
