import { FC } from 'react';
import StatisticsWrapper from '../../features/leave/components/Form/StatisticsWrapper';
import HideComponent from '../../../shared/hideComponent/HideComponent';
import WidgetWrapper from './WidgetWrapper';
import Header from '../layout/Header';
import { BalanceProvider } from '../../features/leave/provider/BalanceProvider';
import { useFeature } from '../../features/feature/FeatureProvider';

const DashboardPage: FC = () => {

  const { showFeature } = useFeature();
  return (
    <>

      {showFeature('TIMESHEET') && <WidgetWrapper />}
      <HideComponent keyVal={'LEAVES'}>
        <div>
          <h4>My Quotas</h4>
          <BalanceProvider>
            <StatisticsWrapper />
          </BalanceProvider>
        </div>
      </HideComponent>
    </>
  );
};

const DashboardWrapper: FC = () => {
  return (
    <>
      <Header title="Dashboard">
        <DashboardPage />
      </Header>
    </>
  );
};

export { DashboardWrapper };
