import { Link } from 'react-router-dom';
import { Team } from '../../../../@types/Team';
import { ExtendedTableColumnType } from '../../../timesheet/components/TimesheetTable';
import ParticipantsCell from '../../../../components/cells/ParticipantsCell';
import AvatarGroupCell from '../../../../components/cells/AvatarGroupCell';
import ActionCell from '../../../../components/cells/ActionCell';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { TPermissions } from '../../../auth';
export const getInitialColumns = (
    id: number | undefined,
    setTeamSelected: (d: Team) => void,
    setCreateModal: (d: boolean) => void,
    setWarningOpen: (d: boolean) => void,
    setSelectedId: (d: number) => void,
    allowIf: (permissions: TPermissions) => boolean,
): ExtendedTableColumnType<Team>[] => [
        {
            dataIndex: 'Name',
            key: 'Name',
            title: 'Name',
            sorter: { multiple: 1 },
            align: 'center',
            default: true,
        },
        {
            dataIndex: 'Leader',
            key: 'Leader',
            title: 'Leader',
            align: 'center',
            render: (cell) => cell?.FullName,
            default: true,

        },
        {
            dataIndex: 'Members',
            key: 'Member',
            title: 'Members',
            align: 'center',
            render: (cell) => <AvatarGroupCell cell={cell} />,
            default: true,
        },
        {
            dataIndex: 'CreatedBy',
            key: 'Creator',
            title: 'Creator',
            align: 'center',
            render: (cell) => cell?.FullName,
            default: false,
        },
        {
            key: 'actions',
            width: 75,
            title: 'Actions',
            default: true,
            render: (cell) => (
                <ActionCell
                    data={cell}
                    items={[
                        cell.Leader?.Id !== id
                            ? {
                                key: 'view_team',
                                label: 'View Team',
                                icon: <EyeOutlined />,
                                onClick: () => {
                                    setTeamSelected(cell);
                                    setCreateModal(true);
                                },
                            }
                            : null,
                        cell.Leader?.Id === id || allowIf(['Teams_EditAll'])
                            ? {
                                key: 'edit_trip',
                                label: 'Edit Team',
                                icon: <EditOutlined />,
                                onClick: () => {
                                    setTeamSelected(cell);
                                    setCreateModal(true);
                                },
                            }
                            : null,
                        cell.Leader?.Id === id || allowIf(['Teams_EditAll'])
                            ? {
                                key: 'delete_trip',
                                label: 'Delete Team',
                                icon: <DeleteOutlined />,
                                onClick: () => {
                                    setSelectedId(cell.Id);
                                    setWarningOpen(true);
                                },
                            }
                            : null,
                    ]}
                />
            ),
            fixed: 'right',
            align: 'center',
        },
    ];

export const TeamActionPerRow = (
    record: Team,
    id: number | undefined,
    setTeamSelected: (d: Team) => void,
    setCreateModal: (d: boolean) => void,
    setWarningOpen: (d: boolean) => void,
    setSelectedId: (d: number) => void,
    allowIf: (permissions: TPermissions) => boolean,
) => {
    return [
        record.Leader.Id !== id
            ? {
                key: 'view_team',
                label: 'View Team',
                icon: <EyeOutlined />,
                onClick: () => {
                    setTeamSelected(record);
                    setCreateModal(true);
                },
            }
            : null,
        record.Leader.Id === id || allowIf(['Teams_EditAll'])
            ? {
                key: 'edit_trip',
                label: 'Edit Team',
                icon: <EditOutlined />,
                onClick: () => {
                    setTeamSelected(record);
                    setCreateModal(true);
                },
            }
            : null,
        record.Leader.Id === id || allowIf(['Teams_EditAll'])
            ? {
                key: 'delete_trip',
                label: 'Delete Team',
                icon: <DeleteOutlined />,
                onClick: () => {
                    setSelectedId(record.Id);
                    setWarningOpen(true);
                },
            }
            : null,
    ];
};
