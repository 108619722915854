import { useBalance } from '../../provider/useBalance';
import SingleCardLeaveType from './SingleCardLeaveType';

const StatisticsWrapper = () => {
  const { balance } = useBalance();
  return (
    <div className="w-full">
      <div className={`grid md:grid-cols-12 grid-cols-4 gap-6`}>
        {balance.filter((b) => b.Policy.TypeOfLeave.Id === 1).map((bal, index) => (
          <SingleCardLeaveType
            year={bal.Year}
            balanceData={bal}
            key={`${bal.Id}-${index}`}
            title={`Remaining ${bal.Policy?.TypeOfLeave?.Name}`}
          />
        ))}
      </div>
    </div>
  );
};

export default StatisticsWrapper;
