import { Team } from "../../@types/Team"
import * as Yup from 'yup';
const initTeam: Team = {
    Id: 0,
    Name: '',
    Members: [],
    Leader: {
        Id: 0,
        FirstName: '',
        LastName: '',
        CallName: '',
        FullName: '',
    },
    Organisation: null,
}
const createTeamSchema = Yup.object().shape({
    Members: Yup.array().min(1, "You need at least one member").required("required"),
    Name: Yup.string().required("Name is a required field.")

})
export {
    initTeam, createTeamSchema
}