export interface AuthModel {
  api_token: string;
  refreshToken?: string;
}
export interface TokenModel {
  api_token: string;
}
export interface UserAddressModel {
  addressLine: string;
  city: string;
  state: string;
  postCode: string;
}

export interface UserCommunicationModel {
  email: boolean;
  sms: boolean;
  phone: boolean;
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean;
  sendCopyToPersonalEmail?: boolean;
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean;
    youAreSentADirectMessage?: boolean;
    someoneAddsYouAsAsAConnection?: boolean;
    uponNewOrder?: boolean;
    newMembershipApproval?: boolean;
    memberRegistration?: boolean;
  };
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean;
    tipsOnGettingMoreOutOfKeen?: boolean;
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean;
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean;
    tipsOnStartBusinessProducts?: boolean;
  };
}

export interface UserSocialNetworksModel {
  linkedIn: string;
  facebook: string;
  twitter: string;
  instagram: string;
}

export interface UserModel {
  id: number;
  username: string;
  password: string | undefined;
  email: string;
  first_name: string;
  last_name: string;
  fullname?: string;
  occupation?: string;
  companyName?: string;
  phone?: string;
  roles?: Array<number>;
  pic?: string;
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru';
  timeZone?: string;
  website?: 'https://keenthemes.com';
  emailSettings?: UserEmailSettingsModel;
  auth?: AuthModel;
  communication?: UserCommunicationModel;
  address?: UserAddressModel;
  socialNetworks?: UserSocialNetworksModel;
}

export const TOKEN_DEFAULT_PERMISSIONS_KEY =
  'http://schemas.clmsuk.com/2013/12/identity/claims/permission';

export type TOriginalToken = {
  exp: number;
  role: 'Administrator' | 'Employee';
  name: string;
  email: string;
  personId: string;
  [TOKEN_DEFAULT_PERMISSIONS_KEY]: TPermissions;
};
export type TToken = {
  permissions: TPermissions;
} & Omit<TOriginalToken, typeof TOKEN_DEFAULT_PERMISSIONS_KEY>;

export type TPermissions = TPermissionAction[];

export type TPermissionAction = keyof typeof PermissionsActions;

export const PermissionsActions = {
  Teams_Read: 'Teams_Read',
  FinancialReporting_ViewAllBeneficiaries: 'FinancialReporting_ViewAllBeneficiaries',
  Trips_EditAll: 'Trips_EditAll',
  Trips_ReadWrite: 'Trips_ReadWrite',
  Trips_Read: 'Trips_Read',
  Meetings_EditAll: 'Meetings_EditAll',
  FinancialReporting_Read: 'FinancialReporting_Read',
  FinancialReporting_Write: 'FinancialReporting_Write',
  FinancialReporting_Approve: 'FinancialReporting_Approve',
  ManageUsers: 'ManageUsers',
  ManageRoles: 'ManageRoles',
  ManagePermissions: 'ManagePermissions',
  ManageOperations: 'ManageOperations',
  ManageSettings: 'ManageSettings',
  ManageApplicationData: 'ManageApplicationData',
  Organisations_ViewAll: 'Organisations_ViewAll',
  Projects_Import: 'Projects_Import',
  StandingData_ReadWrite: 'StandingData_ReadWrite',
  Employees_ViewSalaries: 'Employees_ViewSalaries',
  Employees_ViewSensitive: 'Employees_ViewSensitive',
  ManageAuditLog: 'ManageAuditLog',
  Employees_ManageLeaves: 'Employees_ManageLeaves',
  Employees_ReadWrite: 'Employees_ReadWrite',
  Office_Management: 'Office_Management',
  Projects_ReadWrite: 'Projects_ReadWrite',
  LeaveRequests_ReadWrite: 'LeaveRequests_ReadWrite',
  LeaveApprovals_ReadWrite: 'LeaveApprovals_ReadWrite',
  Dashboard_Read: 'Dashboard_Read',
  Organisations_ReadWrite: 'Organisations_ReadWrite',
  Timesheets_ReadWrite: 'Timesheets_ReadWrite',
  Organisations_ReadOnly: 'Organisations_ReadOnly',
  Projects_Delete: 'Projects_Delete',
  Timesheets_ViewAll: 'Timesheets_ViewAll',
  Employees_Read: 'Employees_Read',
  Timesheets_Approve: 'Timesheets_Approve',
  Projects_Read: 'Projects_Read',
  Forecasts_Read: 'Forecasts_Read',
  Forecasts_ReadWrite: 'Forecasts_ReadWrite',
  ProjectPlan_ReadWrite: 'ProjectPlan_ReadWrite',
  Meetings_ReadWrite: 'Meetings_ReadWrite',
  Meetings_Read: 'Meetings_Read',
  LeaveHistory_View: 'LeaveHistory_View',
  LeaveHistory_ViewAll: 'LeaveHistory_ViewAll',
  Employees_ViewAll: 'Employees_ViewAll',
  Trips_Approve: 'Trips_Approve',
  Teams_EditAll: 'Teams_EditAll',
} as const;
