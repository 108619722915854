import {
    FC,
    useEffect,
    createContext,
    useContext,
    useState,
} from 'react';

import { WithChildren } from '../../@types/Utils';
import envConfig, { EnvConfig } from '../../../enviroment/enviroment';
import { IFeatures } from '../../../enviroment/env.type';


type FeatureContextProps = {
    showFeature: (val: keyof IFeatures) => boolean;
};

const initAuthContextPropsState = {
    showFeature: () => false,
};

const FeatureContext = createContext<FeatureContextProps>(initAuthContextPropsState);
FeatureContext.displayName = 'Feature Controller';

const useFeature = () => {
    return useContext(FeatureContext);
};

const FeatureProvider: FC<WithChildren> = ({ children }) => {
    const [features, setFeatures] = useState<EnvConfig>();
    const [loading, setLoading] = useState<boolean>(false);

    const showFeature = (key: keyof IFeatures) => {
        return features?.getEnvKey(key) as boolean;
    }
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        //We will make an api call here to the tenant manager to get the features mainwhile will return loading...
        setFeatures(envConfig);
    }, []);
    if (loading) return <>Loading Tenant Metadata</>;
    return (
        <FeatureContext.Provider
            value={{
                showFeature
            }}
        >
            {children}
        </FeatureContext.Provider>
    );
};

//changes here so refresh doesnt logout.


export { FeatureProvider, useFeature };
