import { IEnv } from './env.type';

export class EnvConfig {
  constructor() {}

  get env(): IEnv {
    const env = window.ENV;
    if (env === undefined) throw new Error(`Env not found`);
    return env;
  }

  public getEnvKey(key: keyof IEnv) {
    const env = this.env[key];

    if (env === undefined) throw new Error(`Env variable ${key} not found`);
    return castValue(env as string);
  }
}

const castValue = (value: string): boolean | number | string => {
  if (typeof value === 'string') {
    const lowerValue = value.toLowerCase();
    if (lowerValue === 'true') {
      return true;
    } else if (lowerValue === 'false') {
      return false;
    }
  }

  // Check if the value is a valid number
  if (!isNaN(Number(value)) && !isNaN(parseFloat(value))) {
    return Number(value);
  }

  // For all other cases, return the value as is (string)
  return value;
};

const envConfig = new EnvConfig();
export default envConfig;
