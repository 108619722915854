import { FC } from 'react';
import { toAbsoluteUrl } from '../../../helpers';

const RunTimeError: FC = () => {

    return (

        <div className='bg-gray-700 w-screen h-screen flex justify-center flex-col items-center'>
            <img
                data-testid="logoLg"
                alt="Logo"
                src={toAbsoluteUrl('media/logos/logo_dark.png')}
                className="ps-3 h-[75px] app-sidebar-logo-default px-6"
            />
            <h1 className="fw-bolder fs-2hx text-gray-100 mb-4">LOOM</h1>
            <h1 className="fw-bolder fs-2hx text-gray-100 mb-4">Oops!</h1>
            <div className="fw-semibold fs-6 text-gray-300 mb-7">
                Something went wrong
            </div>


            {/* end::Link */}
        </div>

    );
};

export { RunTimeError };
