import { DatePicker, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ErrorMessage } from 'formik';
import React from 'react';
import './css/LoomInput.css';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface DisabledTimes {
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
  disabledMilliseconds?: (
    hour: number,
    minute: number,
    second: number,
  ) => number[];
}
type Props = {
  label: string | React.ReactElement;
  onChange: (date: dayjs.Dayjs, dateString: string | string[]) => void;
  minDate?: dayjs.Dayjs;
  disabled?: boolean;
  disabledTime?: (date: dayjs.Dayjs) => DisabledTimes;
  showTime?:
  | boolean
  | {
    format: string;
    minuteStep: number;
    defaultValue: Dayjs;
    disabledMinutes?: () => number[];
  };
  format: string;
  value: dayjs.Dayjs;
  name?: string;
  required?: boolean;
  maxDate?: dayjs.Dayjs;
  info?: string;
  openPicker?: boolean;
  inputReadOnly?: boolean;
  prefix?: React.ReactElement;
};

const LoomDatePicker = ({
  label,
  onChange,
  minDate,
  disabled,
  disabledTime,
  showTime,
  format,
  value,
  name = '',
  maxDate,
  required = false,
  info = '',
  openPicker,
  inputReadOnly = false,
  prefix,
}: Props) => {
  return (
    <div className="flex w-full flex-col gap-2">
      {label && (
        <div className="flex items-center gap-2">
          <label className={`form-label ${required ? 'required' : ''}`}>
            {label}
          </label>
          {info && (
            <span>
              <Tooltip title={info}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          )}
        </div>
      )}
      <DatePicker
        defaultOpen={false}
        maxDate={maxDate}
        allowClear={false}
        use12Hours={false}
        minDate={minDate}
        onChange={onChange}
        disabled={disabled}
        style={{ color: 'black', width: '100%' }}
        disabledTime={disabledTime}
        showTime={showTime as any}
        format={format}
        className="cursor-pointer h-input"
        value={value}
        open={openPicker}
        inputReadOnly={inputReadOnly}
        prefix={prefix as any}
        autoFocus={false}
      />

      {name.length > 0 && (
        <ErrorMessage name={name} className="text-red-500">
          {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default LoomDatePicker;
