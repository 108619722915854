import { AxiosInstance } from 'axios';
import { TOKEN_DEFAULT_PERMISSIONS_KEY } from '..';
import { NavigateFunction } from 'react-router-dom';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

const getAuthToken = (): string | undefined => {
  if (!localStorage) return;

  const token: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  if (!token) return;

  return JSON.parse(token);
};

const setAuth = (auth: string) => {
  if (!localStorage) {
    return;
  }
  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    console.log('removeAuth FUNCTION');
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('redirectAfterLogin');
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export function setupAxios(
  axios: AxiosInstance,
  navigate: NavigateFunction,
  action: (d: string | undefined) => void,
) {
  axios.defaults.headers.Accept = 'application/json';
  axios.defaults.headers['Content-Type'] = 'application/json';

  axios.interceptors.request.use(
    (config) => {
      const auth = getAuthToken();
      if (auth) {
        config.headers.Authorization = `Bearer ${auth}`;
      }
      return config;
    },
    (err) => Promise.reject(err),
  );

  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.response.status === 401 && getAuthToken()) {
        localStorage.setItem('loggedOut', 'false');
        removeAuth();
        action(undefined);
      }
      return Promise.reject(error);
    },
  );
}
const IsTokenExpired = (token: string): boolean => {
  try {
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    return exp < Date.now() / 1000;
  } catch (err) {
    console.error('Error parsing JWT token:', err);
    return false;
  }
};
function parseJwt<T>(token: string): T | null {
  if (!token) return null;

  try {
    const base64Url = token.split('.')[1];

    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    const parsedToken = JSON.parse(jsonPayload);

    return {
      ...parsedToken,
      permissions: parsedToken[TOKEN_DEFAULT_PERMISSIONS_KEY],
    };
  } catch (error) {
    return null;
  }
}

export {
  getAuthToken,
  setAuth,
  removeAuth,
  AUTH_LOCAL_STORAGE_KEY,
  parseJwt,
  IsTokenExpired,
};
