import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '..';
import { microsoftLogin } from '../core/_requests';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { useNotifications } from '../../../permissions/components/timesheet/Notification-service';
import { AxiosError } from 'axios';

const Callback = () => {
  const { saveAuth, setCurrentUser } = useAuth();
  const { openNotification } = useNotifications();
  const [loading, setLoading] = useState(true);

  const state = localStorage.getItem('nonce');
  const navigate = useNavigate();

  const redirect = useCallback(
    () => navigate('/auth', { state: { showInvalidCredentials: true }, replace: true }),
    [navigate],
  );
  useEffect(() => {
    // console.log(window.location.href.split('#')[1].split('=')[1].split('&')[0]);
    const Login = async () => {
      try {
        if (state) {
          const { data } = await microsoftLogin(
            state,
            window.location.href.split('#')[1].split('=')[1].split('&')[0],
          );
          saveAuth(data.Token);
          setCurrentUser(data.Username);
          // Redirect to the original path or default to "/"

        }
      } catch (error) {
        if (error instanceof AxiosError) {
          openNotification('topRight', {
            title: `Login Failed`,
            description: error?.response?.data.error || 'Please contact the administrator.',
            type: 'Danger',
          });
        }
        redirect();
        console.error(error);
        saveAuth(undefined);
      } finally {
        setLoading(false)
      }
    };
    Login();
  }, []);
  return <div className='justify-center items-center w-screen h-screen flex'><Spin spinning={loading} /></div>
};

export default Callback;
